.x2q__cursor {
    width: 30px;
    height: 30px;
    background-color: var(--btn-color);
    border-radius: 50%;
    position: fixed;
    z-index: 999999;
    pointer-events: none;
    transform: translate(-50%, -50%);
}

.x2q__cursor-anim{
    position: fixed;
    width: 50px;
    height: 50px;
    border: 2px solid var(--btn-color);
    border-radius: 50%;
    transition: all 0.2s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-color);
    background-color: var(--bd-color);
    transform: translate(-50%, -50%);
    pointer-events: none;
   
    
    
  }
  
  .x2q__cursor-anim.hover {
    background-color: var(--bd-color);
    color: var(--text-color);
  }
  
  .x2q__cursor-anim.hover .x2q__cursor-anim {
    border-color: white;
  }

  @media screen and (max-width: 820px){

    .x2q__cursor{
        display: none;
    }
    .x2q__cursor-anim{
      display: none;
    }
  }