
.time-component {
    display: inline-block;
    padding: 18rem 4rem;
    background-color: #131313;
    box-shadow: 8px 8px 12px 0px rgba(0, 0, 0, 0.15);
    color: var(  --text-color);
    margin: -18rem 1rem;
    border-radius: 15%;
    transform: translate(-0%, 55%);
  }
.clockbg h1{
    font-size: 400px;
  }
