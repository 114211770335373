
.x2q__welcome-container{
    background-color: var(--bd-color);
    margin-top: -0.2rem;
}
.x2q__welcome-content{
    display: flex;
    flex-direction: column;
}
.x2q__welcome-paragraph{
    padding-left: 30rem ;
}
.x2q__welcome-paragraph p{
    text-align: right;
}

@media screen and (min-width:1300px){
    .x2q__welcome-paragraph{
        padding-left: 50rem ;
    }
}
@media screen and (max-width:1050px){
    .x2q__welcome-paragraph{
        padding-left: 20rem ;
    }
}
@media screen and (max-width:800px){
    .x2q__welcome-paragraph{
        padding-left: 10rem ;
    }
}
@media screen and (max-width:550px){
    .x2q__welcome-paragraph{
        padding-left: 5rem ;
    }
 
}