.x2q__instareels-content{
    display: flex;
    flex-direction: column;
    border-radius: 50px;
    padding: 5rem 10rem 5rem 10rem;
    justify-content: center;
}

.x2q__instareels-content input{
    margin-bottom: 3rem;
    padding: 1.5rem 1rem 1.5rem 1rem;
    border-radius: 30px;
    outline: none;
    border: none;
}

@media screen and (max-width:550px) {
    .x2q__instareels-content{
    border-radius: 20px;
    padding: 5rem 3rem 5rem 3rem;
    }
}