.x2q__ccmh-container{
    background-color: var(--bd-color);
}

.x2q__ccmh-content{
    display: flex;
    flex-direction:column ;
}
.x2q__ccmh-flex-content{
    display: flex;
    align-items: center;
}
.x2q__ccmh-paragraph{
    flex: 1;
    z-index: 1;
}
.x2q__ccmh-paragraph p{
    text-align: left;
}
.x2q__ccmh-image {
    flex: 1.5;
    margin-left: -5rem;
}
.x2q__ccmh-image img{
    width: 100%;
    height: 100%;
}

@media screen and (max-width:550px){
    .x2q__ccmh-flex-content{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .x2q__ccmh-paragraph{
        padding-right: 5rem;
       
    }
    .x2q__ccmh-paragraph p{
        text-align: left;
    }
    .x2q__ccmh-image {
        padding-top: 3rem;
        margin: 0 -1.5rem;
    }
 
}