.x2q__downloader-container{
    background-color: var(--bd-color);
    border-radius: 0px 0px 50px 50px;
    margin-top: -0.2rem;
    
}

.x2q__downloader-content{
    display: flex;
    flex-direction:column ;
}
.x2q__downloader-flex-content{
    display: flex;
    align-items: center;
}
.x2q__downloader-paragraph{
    flex: 1;
    margin-right: 1rem;
  
}
.x2q__downloader-paragraph p{
    text-align: left;
}
.x2q__downloader-image {
    flex: 1;
    margin-right: -3rem;
}
.x2q__downloader-image img{
    width: 100%;
    height: 100%;
}

@media screen and (max-width:550px){

    .x2q__downloader-container{
        background-color: var(--bd-color);
        border-radius: 0px 0px 20px 20px;
        
    }
    .x2q__downloader-flex-content{
        display: flex;
        flex-direction: column;
        align-items: right;
    }
    .x2q__downloader-paragraph{
        padding-left: 5rem ;
        margin-right: 0;
    }
    .x2q__downloader-paragraph p{
        text-align: right;
    }
    .x2q__downloader-paragraph button {
        display: block;
        float: right;
        
    }
    .x2q__downloader-image {
        padding-top: 3rem;
        margin-right: -0.5rem;
    }
 
}