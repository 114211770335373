.x2q__footer-container{
    padding-top: 6rem;
    padding-bottom: 2rem;
    background-color: var(--bd-color);
    border-radius:50px 50px 0px 0px ;
    margin-top: 1rem;
}
.x2q__footer-header{
    padding-bottom: 3rem;
    padding-left: 4rem;
    padding-right: 4rem;
   
}
.x2q__footer-links{
    display:flex;
    justify-content: center;
}

.x2q__footer-links p{
    padding: 3rem 5rem;
}

@media screen and (max-width:1050px) {
    .x2q__footer-header{
        padding-bottom: 1rem;
       
    }
    .x2q__footer-links p{
        padding: 3rem 3rem;
    }
}

@media screen and (max-width:550px){
    .x2q__footer-container{
        border-radius:20px 20px 0px 0px ;
    }

    .x2q__footer-links{
        display:flex;
        flex-direction: column;
        justify-content: center;
    }
    .x2q__footer-links p{
        padding: 0.5rem 0rem;
    }
    .x2q__footer-header{
        padding-bottom: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
       
    }
}